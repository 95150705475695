.icon-location {

	position: relative;
	padding-left: 1.5rem;

	&:before {
		font-family: 'icons';
		content: '\e724';
		position: absolute;
		left: 0;
	}
	
}

.icon-phone {

	position: relative;
	padding-left: 1.5rem;

	&:before {
		font-family: 'icons';
		content: '📞';
		position: absolute;
		left: 0;
	}
	
}

.icon-mail, .icon-email {

	position: relative;
	padding-left: 1.5rem;

	&:before {
		font-family: 'icons';
		content: '\2709';
		position: absolute;
		left: 0;
	}
	
}