body {
	background: #ddd url("./img/struckaxiom.png");
}

#wrapper {
	padding: 0;
	background: $background-color;
}

#header {
	background: #6a96af url("./img/bg_header_mobile.jpg") no-repeat left top;
	background-size: cover;
	height: 300px;

	h1 {
		color: $header-color;
		padding: 1rem;
		text-align: right;
		font-size: 1.8rem;
		margin-top: 0;
	}
}


@media (min-width: 576px) {
  #header {
		background: transparent url("./img/bg_header_mobile.jpg") no-repeat left top;
		background-size: 100% auto;
		// background-color: red;
		height: 395px; // 500 * 0.79 = 395

		h1 {
			font-size: 2.5rem;
			text-indent: -9999rem;
			margin-top: 1rem;
		}
  }
}

@media (min-width: 768px) {
  #header {
		background: transparent url("./img/bg_header_desktop.jpg") no-repeat left top;
		background-size: 100% auto;
		// background-color: orange;
		height: 300px; // 680 * 0.4515 = 307.02

		h1 {
			color: $header-color;
			text-align: left;
			padding-top: 250px;
			padding-left: 190px;
			font-size: 1.75rem;
		}
	}
}

@media (min-width: 992px) {
  #header {
		background: transparent url("./img/bg_header_desktop.jpg") no-repeat left top;
		background-size: 100% auto;
		// background-color: green;
		height: 400px; // 920 * 0.4514 = 415.38

		h1 {
			color: $header-color;
			text-align: left;
			padding-top: 340px;
			padding-left: 260px;
			font-size: 2.25rem;
		}
  }
}


@media (min-width: 1200px) {
  #header {
		background: transparent url("./img/bg_header_desktop.jpg") no-repeat left top;
		background-size: 100% auto;
		height: 490px; // 1100 * 0.4514 = 496,65

		h1 {
			color: $header-color;
			text-align: left;
			padding-top: 420px;
			padding-left: 320px;
			font-size: 2.5rem;
		}
  }
}

#navbar {
	font-size: 1rem/16*18;
	border-bottom: 1px solid $navbar-link-color;
	padding: .5rem .5rem .5rem .5rem;

	.nav-link {
		padding: .5rem;
	}

	.navbar-toggler {
		margin-left: .5rem;
	}
}

@media (max-width: 768px) {
	#navbar {
		font-size: 1rem;
		padding: .5rem 1rem .5rem .5rem;
	}
}

#main {
	padding: 1rem 1rem;
}

@media (max-width: 768px) {
	#main {
		padding: 1rem 1rem;
	}
}

#footer {
	background-color: $footer-bgcolor;
	font-size: inherit;
	color: $footer-color;
	padding: 1rem 4rem;

	.container {
		padding-bottom: 1rem;

		.container {
			padding-bottom: 0;
		}
	}

	ul {
		margin-bottom: 1rem;
	}

	h1, h2, h3, h4, h5, h6 {
		font-size: 1.1rem;
	}

	.nav-link {
		padding: 0;
	}
}