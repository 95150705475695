.ce_accordion {
	clear: both;
/*
	.ui-accordion-header {
		
	}
	.ui-accordion-content {

	}
	*/

	
}

.last.ce_accordion {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
