
main {
	a {
		&:hover {
			text-decoration: none;
		}	
	}
}

footer {
	a {
		color: $footer-link-color;
		&:hover, &:focus {
			color: $main-link-color; // $footer-link-color;
			text-decoration: none;
			// font-weight: bold;
		}
	}
}