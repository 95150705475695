.ce_gallery {
	figure {
		a {
			display: block;
			width: auto;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}