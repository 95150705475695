h1, h2, h3, h4, h5, h6 {
	font-family: 'Crimson Pro', "Times New Roman", Times, serif;
	font-style: italic;
	margin: 1.5rem 0 .5rem;
}



ul {
	list-style-type: square;
	padding-left: 1rem;
}