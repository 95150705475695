.mod_eventlist {

	/*
	:first-child {
		margin-top: 0;
	}
	*/
	
	.event {
		margin-top: 1rem;
		
	}

	@media (min-width: 768px) {
		.event-date { order: 1 }
		.event-heading { order: 2 }
	}

	/* date */
	.header {
		font-family: 'Crimson Pro', "Times New Roman", Times, serif;
		font-style: italic;
		font-weight: 500;
		font-size: 1.5rem;
		margin: 0 0 .5rem;
	}

	/* event headline */
	h3 {
		margin-top: 0;
	}

	figure {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

}
