.ce_text {
	clear: both;

	:first-child {
		h1, h2, h3, h4, h5, h6 {
			&:first-child {
				// margin-top: -.65rem;
			}
		}
	}

	.container-flex {

		@media (max-width: 768px) {
		
			.row {
				.col-md-9 {
					order: 2;
				}
				.col-md-3 {
					order: 1;
				}
			}
	}

		figure {
			width: 100%;
	
			img {
				width: 100%;
				height: auto;
			}
		}
	}

}

/*
.ce_text.first.block {
	div div div {
		:first-child {
			margin-top: 0;
		}
	}
}
*/



